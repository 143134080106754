import React, { lazy } from 'react';
import Page from './Page';
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import DemoView from './DemoView';
import LoadingView from './views/LoadingView';

const env = require('./settings/environment');

const StartView = lazy(() => import('./views/StartView'));
const BettiView = lazy(() => import('./views/BettiView'));
const PreflightView = lazy(() => import('./views/PreflightView'));
const ImprintView = lazy(() => import('./views/ImprintView'));
const DSGVOView = lazy(() => import('./views/DSGVOView'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page />,
    children: [{
        path: "",
        element: <PreflightView />,
    },
    {
      path: "imprint",
      element: <ImprintView />,
    },
    {
      path: "dsgvo",
      element: <DSGVOView />,
    },
    {
      path: "start",
      element: <StartView />,
    },
    {
        path: "betti",
        element: <BettiView />,
    }
  ]}, {
    path: "/demo",
    element: <DemoView />
  }
], { basename: env.basename });

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

