import React from 'react';

import "./styles/DemoView.scss";
import "./styles/Framework.scss";
import "./styles/Color.scss";
import "./styles/Layout.scss";
import { useLocation, useNavigate } from 'react-router-dom';

const DemoView = () => {

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <section className='demo-view'>
        <section className='hint-panel'>
            <div></div>
            <div onClick={() => navigate('/') }>EXIT DEMO</div>
            <div></div>
        </section>
        <iframe src={location?.state?.url} title='DEMO'></iframe>
    </section>
  );
}

export default DemoView;
