import React from 'react';

import "../styles/views/LoadingView.scss";

import "../styles/Framework.scss";
import "../styles/Color.scss";
import "../styles/Layout.scss";

const LoadingView = () => {
  return (
    <section className='loading-view'>
    </section>
  );
}

export default LoadingView;
