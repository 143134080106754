import React from 'react';

import "../styles/Framework.scss";
import "../styles/Color.scss";
import "../styles/Layout.scss";

import "../styles/components/TopPanel.scss";
import { useNavigate } from 'react-router-dom';

const modeChange = (theme, other) => {
    const body = document.querySelector("body");
    body.classList.add(theme);
    body.classList.remove(other);
}

const TopPanel = () => {

  const navigate = useNavigate();

  return (
    <section className='top-panel'>
        <div onClick={() => navigate('/')} className='logo'></div>
        <div onClick={() => navigate('/')} className='betti'>Betti</div>
        <div></div>
        <div className='mode'>
            <div className="mode-icon">
                <i className="fa-solid fa-moon"></i>
            </div>
            <button className="mode-left" onClick={() => modeChange('dark-theme', 'light-theme')}>Dark</button>
            <button className="mode-right" onClick={() => modeChange('light-theme', 'dark-theme')}>Light</button>
            <div className="mode-icon">
                <i className="fa-solid fa-sun"></i>
            </div>
        </div>
    </section>
  );
}

export default TopPanel;
