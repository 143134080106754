import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import "./styles/Page.scss";
import "./styles/Framework.scss";
import "./styles/Color.scss";
import "./styles/Layout.scss";

import TopPanel from './components/TopPanel';
import LoadingView from './views/LoadingView';

const Page = () => {
  return (
    <section className='page'>
      <section className='top-panel'>
        <TopPanel />
      </section>
      <section className='registration-row'>
        <div className='left-triangle'></div>
        <div className='content'>
          <select size="1">
            <option>Deutsch</option>
          </select>
        </div>
        <div className='right-triangle'></div>
      </section>
      <section className='page-viewport'>
        <Suspense fallback={ <LoadingView/> }><Outlet/></Suspense>
      </section>
    </section>
  );
}

export default Page;
